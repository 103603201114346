.cx--btn{
    border: none;
    padding: 12px 30px;
    border-radius: $border-radius-5;
    font-size: $font-size-16;
    color: $white-color;
    font-weight: 500;
    text-transform: capitalize;
    transition: all 0.3s;
    width: 100%;
    cursor: pointer;
    &:active,
    &:focus{
        outline: none;
        border: none;
    }
}

@media (min-width: 768px) {
    .cx--btn{
        width: fit-content;
        font-size: $font-size-18;
    }
}

.cx--btn-primary{
    background-color: $primary-color;
    &:hover{
        background-color: darken($primary-color, $amount: 10%);
    }
}

.cx--btn-danger{
    background-color: $danger-color;
    &:hover{
        background-color: darken($danger-color, $amount: 10%);
    }
}

.cx--btn-success{
    background-color: $success-color;
    &:hover{
        background-color: darken($success-color, $amount: 10%);
    }
}

.cx--btn-warning{
    background-color: $warning-color;
    &:hover{
        background-color: darken($warning-color, $amount: 10%);
    }
}

.cx--btn-white{
    background-color: $white-color;
    color: $primary-color;
    &:hover{
        opacity: 0.8;
    }
}

.cx--btn-primary-outline{
    background-color: transparent;
    color: $primary-color;
    border: 1px solid $primary-color;
    &:hover{
        color: $white-color;
        background-color: $primary-color;
    }
}

.cx--btn-white-outline{
    background-color: transparent;
    color: $white-color;
    border: 1px solid $white-color;
    &:hover{
        color: $primary-color;
        background-color: $white-color;
    }
}

.cx--btn-secondary-outline{
    background-color: transparent;
    color: $white-color;
    border: 1px solid $white-color;
    &:hover{
        color: $white-color;
        background-color: $secondary-color;
    }
}

.cx--btn-inline-group{
    display: flex;
    button{
        margin: 10px 5px;
    }
}