.cx--cover-container{
    position: relative;
    .cx--cover-decor{
        width: 100%;
        height: auto;
        max-width: 1100px;
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
    }
    .cx--cover-body-wrapper{
        padding-top: 120px;
        padding-bottom: 30px;
        .cx--cover-title{
            font-size: $font-size-50;
            color: $dark-color;
            line-height: 1.1;
            @extend .font-Bold;
        }

        .cx--cover-text{
            color: $dark-color;
            opacity: 0.8;
            font-size: $font-size-20;
            line-height: 1.5;
            max-width: 750px;
            margin: 0;
        }
        
        @media only screen and (min-width: 1200px){
            .cx--cover-title{
                // font-size: $font-size-90;
                font-size: $font-size-60;
            }
        }

        @media (max-width: 1199.98px) and (min-width: 768px) {
            .cx--cover-title{
                font-size: $font-size-70;
            }
        }

        .cx--cover-form{
            padding: 20px 30px;
            box-shadow: 0 0 30px rgba($color: $primary-color, $alpha: 0.1);
            background-color: $white-color;
            border-radius: $border-radius-10;
            .cx--cover-form-title{
                color: $dark-color;
                text-transform: capitalize;
                @extend .font-Bold;
            }
            .cx--cover-sub-title{
                color: $dark-color;
                font-size: $font-size-14;
                a{
                    color: $primary-color;
                    text-transform: capitalize;
                }
            }
        }
    }

    @media (min-width: 768px) {
        .cx--cover-body-wrapper{
            padding-bottom: 60px;
            padding-top: 220px;
        }
    }

    .cx--cover-image{
        display: none;
        text-align: center;
    }

    @media (min-width: 992px){
        .cx--cover-image{
            display: block;
            img{
                width: 100%;
                margin: 0 auto;
                max-width: 570px;
            }
        }
    }
}

.cx--cover-bottom-container{
    .cx--cover-bottom-title-wrapper{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .cx--cover-bottom-title{
            color: $white-color;
            margin-left: 15px;
            h3{
                font-size: $font-size-18;
                color: $white-color;
                letter-spacing: 2px;
                margin: 0;
                text-transform: uppercase;
            }
            p{
                margin: 0;
            }
        }
    }
}

@media (min-width: 992px){
    .cx--cover-bottom-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .cx--cover-bottom-title-wrapper{
            margin: 0;
            .cx--cover-bottom-title{
                h3{
                    font-size: $font-size-24 !important;
                }
            }
        }
    }
}