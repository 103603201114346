.cx--alert-container {
  position: fixed;
  left: 10px;
  bottom: 0;
  z-index: 1010;

  .cx--alert-block {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    padding: 15px 5px;
    border-radius: 10px;
    z-index: 49;
    background-color: $dark-color;
    max-width: 500px;
    min-width: 400px;
    margin-bottom: 10px;
    box-shadow: 0 0 3px 0px $white-color;

    .alert-block-wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: flex-start;

      .alertText {
        color: $white-color !important;
        margin: 0 15px;
        font-weight: normal;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      img {
        width: 25px;
        opacity: 0.9;
      }

      .close-icon {
        margin-left: auto;
        width: 20px !important;
        cursor: pointer;
      }
    }
  }

  @-webkit-keyframes fadeInLeft {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }

    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }

  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }

    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
}

.cx--modal-window {
  position: relative;

  .cx--modal-window-wrapper {
      position: fixed;
      width: 100vw;
      height: 100vh;
      z-index: 1000;
      top: 0;
      background-color: rgba(0, 0, 0, 0.7);
      backdrop-filter: blur(2px);

      .cx--modal {
          position: fixed;
          top: 150px;
          left: 50%;
          transform: translateX(-50%);
          background-color: $white-color;
          border-radius: $border-radius-10;
          max-width: 600px;
          width: calc(100% - 30px);
          padding: 25px;

          img {
              width: 25px;
              margin-right: 15px;
              margin-top: 3px;
          }
      }

  }
}