@font-face {
  font-family: "MADE TOMMY";
  src: url("../fonts/MADETOMMY-Medium.eot");
  src: url("../fonts/MADETOMMY-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/MADETOMMY-Medium.woff2") format("woff2"), url("../fonts/MADETOMMY-Medium.woff") format("woff"), url("../fonts/MADETOMMY-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "MADE TOMMY";
  src: url("../fonts/MADETOMMY-Black.eot");
  src: url("../fonts/MADETOMMY-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/MADETOMMY-Black.woff2") format("woff2"), url("../fonts/MADETOMMY-Black.woff") format("woff"), url("../fonts/MADETOMMY-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "MADE TOMMY";
  src: url("../fonts/MADETOMMY-ExtraBold.eot");
  src: url("../fonts/MADETOMMY-ExtraBold.eot?#iefix") format("embedded-opentype"), url("../fonts/MADETOMMY-ExtraBold.woff2") format("woff2"), url("../fonts/MADETOMMY-ExtraBold.woff") format("woff"), url("../fonts/MADETOMMY-ExtraBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "MADE TOMMY";
  src: url("../fonts/MADETOMMY-Light.eot");
  src: url("../fonts/MADETOMMY-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/MADETOMMY-Light.woff2") format("woff2"), url("../fonts/MADETOMMY-Light.woff") format("woff"), url("../fonts/MADETOMMY-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "MADE TOMMY";
  src: url("../fonts/MADETOMMY-Bold.eot");
  src: url("../fonts/MADETOMMY-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/MADETOMMY-Bold.woff2") format("woff2"), url("../fonts/MADETOMMY-Bold.woff") format("woff"), url("../fonts/MADETOMMY-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "MADE TOMMY";
  src: url("../fonts/MADETOMMY.eot");
  src: url("../fonts/MADETOMMY.eot?#iefix") format("embedded-opentype"), url("../fonts/MADETOMMY.woff2") format("woff2"), url("../fonts/MADETOMMY.woff") format("woff"), url("../fonts/MADETOMMY.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "MADE TOMMY";
  src: url("../fonts/MADETOMMY-Thin.eot");
  src: url("../fonts/MADETOMMY-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/MADETOMMY-Thin.woff2") format("woff2"), url("../fonts/MADETOMMY-Thin.woff") format("woff"), url("../fonts/MADETOMMY-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
.cx--primary-background {
  background-color: #0533B3;
}

.cx--dark-background {
  background-color: #222F3E;
}

.cx--gray-background {
  background-color: #F9F9F9;
}

.cx--btn {
  border: none;
  padding: 12px 30px;
  border-radius: 5px;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 500;
  text-transform: capitalize;
  transition: all 0.3s;
  width: 100%;
  cursor: pointer;
}
.cx--btn:active, .cx--btn:focus {
  outline: none;
  border: none;
}

@media (min-width: 768px) {
  .cx--btn {
    width: -moz-fit-content;
    width: fit-content;
    font-size: 18px;
  }
}
.cx--btn-primary {
  background-color: #0533B3;
}
.cx--btn-primary:hover {
  background-color: #042581;
}

.cx--btn-danger {
  background-color: #B30505;
}
.cx--btn-danger:hover {
  background-color: #810404;
}

.cx--btn-success {
  background-color: #05B32E;
}
.cx--btn-success:hover {
  background-color: #048121;
}

.cx--btn-warning {
  background-color: #ffcc00;
}
.cx--btn-warning:hover {
  background-color: #cca300;
}

.cx--btn-white {
  background-color: #FFFFFF;
  color: #0533B3;
}
.cx--btn-white:hover {
  opacity: 0.8;
}

.cx--btn-primary-outline {
  background-color: transparent;
  color: #0533B3;
  border: 1px solid #0533B3;
}
.cx--btn-primary-outline:hover {
  color: #FFFFFF;
  background-color: #0533B3;
}

.cx--btn-white-outline {
  background-color: transparent;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
}
.cx--btn-white-outline:hover {
  color: #0533B3;
  background-color: #FFFFFF;
}

.cx--btn-secondary-outline {
  background-color: transparent;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
}
.cx--btn-secondary-outline:hover {
  color: #FFFFFF;
  background-color: #F9AE30;
}

.cx--btn-inline-group {
  display: flex;
}
.cx--btn-inline-group button {
  margin: 10px 5px;
}

input[type=text],
input[type=textarea],
input[type=number],
input[type=tel],
input[type=email],
input[type=password],
input[type=date],
input[type=datetime],
textarea {
  padding: 18px 20px;
  border-radius: 5px;
  outline: none;
  font-weight: normal;
  border: 1px solid #CCCCCC;
  width: 100%;
  position: relative;
  background-color: transparent;
  z-index: 1;
  font-size: 16px;
}
input[type=text]::-moz-placeholder, input[type=textarea]::-moz-placeholder, input[type=number]::-moz-placeholder, input[type=tel]::-moz-placeholder, input[type=email]::-moz-placeholder, input[type=password]::-moz-placeholder, input[type=date]::-moz-placeholder, input[type=datetime]::-moz-placeholder, textarea::-moz-placeholder {
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0;
}
input[type=text]::placeholder,
input[type=textarea]::placeholder,
input[type=number]::placeholder,
input[type=tel]::placeholder,
input[type=email]::placeholder,
input[type=password]::placeholder,
input[type=date]::placeholder,
input[type=datetime]::placeholder,
textarea::placeholder {
  transition: all 0.3s;
  opacity: 0;
}
input[type=text]:focus, input[type=text]:active,
input[type=textarea]:focus,
input[type=textarea]:active,
input[type=number]:focus,
input[type=number]:active,
input[type=tel]:focus,
input[type=tel]:active,
input[type=email]:focus,
input[type=email]:active,
input[type=password]:focus,
input[type=password]:active,
input[type=date]:focus,
input[type=date]:active,
input[type=datetime]:focus,
input[type=datetime]:active,
textarea:focus,
textarea:active {
  border-color: #B2B2B2;
}
input[type=text]:focus::-moz-placeholder, input[type=text]:active::-moz-placeholder, input[type=textarea]:focus::-moz-placeholder, input[type=textarea]:active::-moz-placeholder, input[type=number]:focus::-moz-placeholder, input[type=number]:active::-moz-placeholder, input[type=tel]:focus::-moz-placeholder, input[type=tel]:active::-moz-placeholder, input[type=email]:focus::-moz-placeholder, input[type=email]:active::-moz-placeholder, input[type=password]:focus::-moz-placeholder, input[type=password]:active::-moz-placeholder, input[type=date]:focus::-moz-placeholder, input[type=date]:active::-moz-placeholder, input[type=datetime]:focus::-moz-placeholder, input[type=datetime]:active::-moz-placeholder, textarea:focus::-moz-placeholder, textarea:active::-moz-placeholder {
  opacity: 0.7;
}
input[type=text]:focus::placeholder, input[type=text]:active::placeholder,
input[type=textarea]:focus::placeholder,
input[type=textarea]:active::placeholder,
input[type=number]:focus::placeholder,
input[type=number]:active::placeholder,
input[type=tel]:focus::placeholder,
input[type=tel]:active::placeholder,
input[type=email]:focus::placeholder,
input[type=email]:active::placeholder,
input[type=password]:focus::placeholder,
input[type=password]:active::placeholder,
input[type=date]:focus::placeholder,
input[type=date]:active::placeholder,
input[type=datetime]:focus::placeholder,
input[type=datetime]:active::placeholder,
textarea:focus::placeholder,
textarea:active::placeholder {
  opacity: 0.7;
}
input[type=text]:-webkit-autofill,
input[type=textarea]:-webkit-autofill,
input[type=number]:-webkit-autofill,
input[type=tel]:-webkit-autofill,
input[type=email]:-webkit-autofill,
input[type=password]:-webkit-autofill,
input[type=date]:-webkit-autofill,
input[type=datetime]:-webkit-autofill,
textarea:-webkit-autofill {
  box-shadow: 0 0 0 30px white inset !important;
}
input[type=text]:not(:-moz-placeholder-shown) ~ span, input[type=textarea]:not(:-moz-placeholder-shown) ~ span, input[type=number]:not(:-moz-placeholder-shown) ~ span, input[type=tel]:not(:-moz-placeholder-shown) ~ span, input[type=email]:not(:-moz-placeholder-shown) ~ span, input[type=password]:not(:-moz-placeholder-shown) ~ span, input[type=date]:not(:-moz-placeholder-shown) ~ span, input[type=datetime]:not(:-moz-placeholder-shown) ~ span, textarea:not(:-moz-placeholder-shown) ~ span {
  opacity: 0.6;
  transform: translateY(0);
  font-size: 14px;
  z-index: 2;
  opacity: 1;
  top: -10px;
  background-color: #FFFFFF;
  padding: 1px 8px;
  border-radius: 3px;
}
input[type=text]:focus ~ span, input[type=text]:not(:placeholder-shown) ~ span,
input[type=textarea]:focus ~ span,
input[type=textarea]:not(:placeholder-shown) ~ span,
input[type=number]:focus ~ span,
input[type=number]:not(:placeholder-shown) ~ span,
input[type=tel]:focus ~ span,
input[type=tel]:not(:placeholder-shown) ~ span,
input[type=email]:focus ~ span,
input[type=email]:not(:placeholder-shown) ~ span,
input[type=password]:focus ~ span,
input[type=password]:not(:placeholder-shown) ~ span,
input[type=date]:focus ~ span,
input[type=date]:not(:placeholder-shown) ~ span,
input[type=datetime]:focus ~ span,
input[type=datetime]:not(:placeholder-shown) ~ span,
textarea:focus ~ span,
textarea:not(:placeholder-shown) ~ span {
  opacity: 0.6;
  transform: translateY(0);
  font-size: 14px;
  z-index: 2;
  opacity: 1;
  top: -10px;
  background-color: #FFFFFF;
  padding: 1px 8px;
  border-radius: 3px;
}

.cx--form-control {
  position: relative;
  margin-bottom: 2rem;
}
.cx--form-control span {
  position: absolute;
  color: #5d6164;
  left: 20px;
  top: 18px;
  transition: all 0.3s;
  font-size: 16px;
  text-transform: capitalize;
}

@media (min-width: 768px) {
  input {
    font-size: 18px;
  }
  .cx--form-control span {
    font-size: 18px;
  }
}
.react-tel-input input:focus .custom-phone-number-input-label {
  opacity: 0.6;
  transform: translateY(0);
  font-size: 14px;
  z-index: 2;
  opacity: 1;
  top: -10px;
  background-color: #FFFFFF;
  padding: 1px 8px;
  border-radius: 3px;
}

.cx--auth-control input {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.3215686275);
  color: #FFFFFF;
}
.cx--auth-control input::-moz-placeholder {
  color: #FFFFFF !important;
}
.cx--auth-control input::placeholder {
  color: #FFFFFF !important;
}
.cx--auth-control input:focus {
  border-color: #88E1FD;
}
.cx--auth-control input:focus::-moz-placeholder {
  color: #FFFFFF;
}
.cx--auth-control input:focus::placeholder {
  color: #FFFFFF;
}
.cx--auth-control span {
  color: #FFFFFF;
}

.select-fail .cryllex-select__control {
  border-color: #B30505 !important;
}

.cx--danger-border {
  border-color: #B30505 !important;
}

.cx--danger-border-2 {
  border: 2px solid #B30505 !important;
}

.cx--success-border {
  border-color: #05B32E !important;
}

.cx--warning-border {
  border-color: #ffcc00 !important;
}

/* Slider */
.slick-slide {
  margin: 0px 20px;
}

.slick-slide img {
  width: 100%;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.spinner-layer {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #FFFFFF;
  top: 0;
  z-index: 1002;
  opacity: 0.9;
}
.spinner-layer .sweet-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.cx--alert-container {
  position: fixed;
  left: 10px;
  bottom: 0;
  z-index: 1010;
}
.cx--alert-container .cx--alert-block {
  animation-name: fadeInLeft;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  padding: 15px 5px;
  border-radius: 10px;
  z-index: 49;
  background-color: #222F3E;
  max-width: 500px;
  min-width: 400px;
  margin-bottom: 10px;
  box-shadow: 0 0 3px 0px #FFFFFF;
}
.cx--alert-container .cx--alert-block .alert-block-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.cx--alert-container .cx--alert-block .alert-block-wrapper .alertText {
  color: #FFFFFF !important;
  margin: 0 15px;
  font-weight: normal;
  text-overflow: ellipsis;
  overflow: hidden;
}
.cx--alert-container .cx--alert-block .alert-block-wrapper img {
  width: 25px;
  opacity: 0.9;
}
.cx--alert-container .cx--alert-block .alert-block-wrapper .close-icon {
  margin-left: auto;
  width: 20px !important;
  cursor: pointer;
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.cx--modal-window {
  position: relative;
}
.cx--modal-window .cx--modal-window-wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
}
.cx--modal-window .cx--modal-window-wrapper .cx--modal {
  position: fixed;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #FFFFFF;
  border-radius: 10px;
  max-width: 600px;
  width: calc(100% - 30px);
  padding: 25px;
}
.cx--modal-window .cx--modal-window-wrapper .cx--modal img {
  width: 25px;
  margin-right: 15px;
  margin-top: 3px;
}

header {
  position: fixed;
  width: 100%;
  padding: 15px 0;
  z-index: 100;
  transition: all 0.3s;
  top: 0;
}
header .dropdown {
  min-width: 200px !important;
}
header .dropdown .nav-link {
  color: #0533B3 !important;
  font-size: 16px;
  padding: 10px 15px !important;
  text-transform: capitalize;
  transition: all 0.2s;
}
header .dropdown .nav-link:hover {
  opacity: 0.6;
  color: #0533B3;
}
header .navbar-brand {
  display: flex;
}
header .navbar-brand img {
  max-height: 30px;
}

header.cx--header-active {
  padding: 5px 0;
  background-color: #FFFFFF;
  box-shadow: 0 5px 15px rgba(34, 47, 62, 0.2);
}

@media (min-width: 768px) {
  header {
    padding: 50px 0;
  }
  header .navbar-brand img {
    max-height: 35px;
  }
  header.cx--header-active {
    padding: 10px 0;
  }
}
.header-profile-user-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #0533B3;
}

.header-profile-user {
  color: #FFFFFF;
  border-radius: 50%;
  padding: 10px;
  background-color: #0533B3;
}

.cx--burger-menu-wrapper {
  position: relative;
  z-index: 5;
}
.cx--burger-menu-wrapper input {
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  opacity: 0;
  z-index: 15;
  -webkit-touch-callout: none;
}
.cx--burger-menu-wrapper input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(4px, -6px);
  background-color: #0533B3;
}
.cx--burger-menu-wrapper input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}
.cx--burger-menu-wrapper input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0px, 10px);
  background-color: #0533B3;
}
.cx--burger-menu-wrapper span {
  display: block;
  width: 40px;
  height: 3px;
  margin: 5px 0;
  position: relative;
  right: 0;
  background: #0533B3;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}
.cx--burger-menu-wrapper span:first-child {
  transform-origin: 0% 0%;
}
.cx--burger-menu-wrapper span:nth-last-child(2) {
  transform-origin: 0% 100%;
}
.cx--burger-menu-wrapper .cx--burger-menu {
  position: fixed;
  width: 100%;
  max-width: 400px;
  height: 100%;
  margin: 0;
  padding: 50px;
  text-align: center;
  right: 0;
  top: 0;
  background: #FFFFFF;
  overflow-y: auto;
  -webkit-font-smoothing: antialiased;
  box-shadow: 0 -5px 15px rgba(34, 47, 62, 0.2);
  transform-origin: 0% 0%;
  transform: translate(500px, 0);
  transition: transform 0.55s cubic-bezier(0.77, 0.2, 0.05, 1);
}
.cx--burger-menu-wrapper .cx--burger-menu .cx--signed-customer {
  text-align: center;
  margin-top: 15px;
}
.cx--burger-menu-wrapper .cx--burger-menu .cx--signed-customer .cx--customer-avatar {
  width: 75px;
  height: 75px;
}
.cx--burger-menu-wrapper .cx--burger-menu .cx--signed-customer .cx--customer-avatar .signed-avatar {
  width: 70px;
  height: 70px;
}
.cx--burger-menu-wrapper .cx--burger-menu .cx--signed-customer .cx--signed-cutomer-name {
  margin-top: 10px;
  margin-bottom: 0;
  color: #222F3E;
  display: block;
  font-size: 20px;
  text-transform: capitalize;
}
.cx--burger-menu-wrapper .cx--burger-menu .cx--signed-customer .cx--signed-cutomer-name:hover {
  color: #0533B3;
  text-decoration: none;
}
.cx--burger-menu-wrapper .cx--burger-menu ul {
  list-style-type: none;
  padding: 0;
  margin-top: 15px;
}
.cx--burger-menu-wrapper .cx--burger-menu ul .cx--nav-burger-item {
  text-align: right;
}
.cx--burger-menu-wrapper .cx--burger-menu ul .cx--nav-burger-item a {
  border: 1px solid transparent;
  display: block;
  font-size: 16px;
  text-decoration: none;
  text-transform: capitalize;
  color: #0533B3;
  font-weight: 500;
  transition: all 0.2s;
  padding: 4px 0;
}
.cx--burger-menu-wrapper .cx--burger-menu ul .cx--nav-burger-item a:hover {
  opacity: 0.8;
}
.cx--burger-menu-wrapper .cx--burger-menu ul li.active a {
  opacity: 0.8;
}
.cx--burger-menu-wrapper input:checked ~ .cx--burger-menu {
  transform: none;
}
.cx--burger-menu-wrapper .cx--unsigned-customer ul {
  margin-top: 80px;
}

@media screen and (min-width: 1200px) {
  .cx--burger-menu-wrapper {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .cx--screen-menu {
    display: none;
  }
}
.cx--signed-customer-wrapper {
  display: flex !important;
  padding: 10px 0;
  align-items: center;
}
.cx--signed-customer-wrapper .cx--signed-nav-link {
  padding: 0 0.75rem !important;
  position: relative;
}
.cx--signed-customer-wrapper .cx--signed-nav-link input {
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  opacity: 0;
  z-index: 15;
  -webkit-touch-callout: none;
}
.cx--signed-customer-wrapper .cx--signed-nav-link input:checked ~ .cx--signed-user-modal,
.cx--signed-customer-wrapper .cx--signed-nav-link input:checked ~ .burgers {
  display: block;
}
.cx--signed-customer-wrapper .cx--signed-nav-link .cx--customer-avatar {
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  box-shadow: 0 0 3px rgba(34, 47, 62, 0.4);
}
.cx--signed-customer-wrapper .cx--signed-nav-link .cx--customer-avatar .signed-avatar {
  width: 35px;
  height: 35px;
  border-radius: 50px;
  border: 1px solid #E5E5E5;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.cx--signed-customer-wrapper .cx--signed-nav-link .cx--signed-user-modal {
  display: none;
  position: absolute;
  list-style: none;
  min-width: 200px;
  right: 0;
  padding: 0;
  margin: 8px 0;
  background-color: #FFFFFF;
  text-align: right;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(34, 47, 62, 0.2);
}
.cx--signed-customer-wrapper .cx--signed-nav-link .cx--signed-user-modal li {
  margin: 5px 0;
}
.cx--signed-customer-wrapper .cx--signed-nav-link .cx--signed-user-modal li .cx--signed-cutomer-name {
  font-size: 16px;
  padding: 10px 20px;
  line-height: 1;
  font-weight: 500;
  margin: 0;
  white-space: nowrap;
}
.cx--signed-customer-wrapper .cx--signed-nav-link .cx--signed-user-modal li .cx--signed-cutomer-name:hover {
  color: #0533B3;
  text-decoration: none;
}
.cx--signed-customer-wrapper .cx--signed-nav-link .cx--signed-user-modal li a {
  font-size: 16px;
  display: block;
  padding: 5px 20px;
  color: #222F3E;
  text-decoration: none;
}
.cx--signed-customer-wrapper .cx--signed-nav-link .cx--signed-user-modal li a:hover {
  color: #0533B3;
  text-decoration: none;
}
.cx--signed-customer-wrapper .cx--signed-nav-link .cx--signed-user-modal li a::first-letter {
  text-transform: capitalize;
}
.cx--signed-customer-wrapper .cx--signed-nav-link .cx--signed-user-modal .hr {
  border-bottom: 1px solid #E5E5E5;
}

.header {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
}
.header li {
  position: relative;
  padding: 10px 20px;
  white-space: nowrap;
  color: #0533B3;
  transition: 0.3s;
}
.header li:hover {
  cursor: pointer;
  transform: scale(1.03);
}
.header li .main-link {
  text-decoration: none;
  color: #0533B3;
}
.header li .dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: auto;
  padding: 10px 0;
  border-radius: 5px;
  background: #FFFFFF;
  box-shadow: 0px 2px 20px rgba(5, 51, 179, 0.15);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.header li .dropdown li {
  padding: 10px;
  list-style: none;
}
.header li .dropdown li a {
  padding: 0 10px !important;
  text-decoration: none;
}
.header li:hover .dropdown {
  display: block;
  opacity: 1;
}

.cx--nav-accordion .accordion-item {
  border: none;
}
.cx--nav-accordion .button-without-icon button::before {
  display: none !important;
}
.cx--nav-accordion .cx--nav-accordion-header button {
  display: flex;
  justify-content: end;
  align-items: center;
  border-top: 1px solid #E5E5E5;
  padding: 15px !important;
}
.cx--nav-accordion .cx--nav-accordion-header button:not(.collapsed) {
  background-color: transparent;
  box-shadow: none;
}
.cx--nav-accordion .cx--nav-accordion-header button::after {
  display: none;
}
.cx--nav-accordion .cx--nav-accordion-header button:focus {
  outline: none;
  box-shadow: none;
}
.cx--nav-accordion .cx--nav-accordion-header button:not(.collapsed)::before {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.cx--nav-accordion .cx--nav-accordion-header button::before {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-right: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
.cx--nav-accordion .cx--nav-accordion-header button.collapsed {
  border-radius: 5px;
  align-items: center;
}
.cx--nav-accordion .cx--nav-accordion-body {
  padding: 20px 30px 10px;
  background-color: rgba(218, 226, 235, 0.2);
  border-radius: 5px;
  margin-bottom: 10px;
}

.right-0 {
  right: 0;
}

.left-auto {
  left: auto !important;
}

footer .cx--footer-logo img {
  width: 100%;
  max-width: 220px;
}
footer .cx--footer-title {
  font-size: 20px;
  margin-bottom: 15px;
  display: block;
}
footer ul {
  list-style: none;
  padding: 0;
}
footer .cx--footer-list .cx--footer-list-item {
  margin: 10px 0;
}
footer .cx--footer-list .cx--footer-list-item a {
  color: #222F3E;
}
footer .cx--footer-social-list {
  display: flex;
  align-items: center;
}
footer .cx--footer-social-list .cx--footer-social-item {
  margin-right: 10px;
  opacity: 0.5;
  transition: all 0.3s;
}
footer .cx--footer-social-list .cx--footer-social-item img {
  max-width: 40px;
}
footer .cx--footer-social-list .cx--footer-social-item:hover {
  opacity: 1;
}

.cx--cover-container {
  position: relative;
}
.cx--cover-container .cx--cover-decor {
  width: 100%;
  height: auto;
  max-width: 1100px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
.cx--cover-container .cx--cover-body-wrapper {
  padding-top: 120px;
  padding-bottom: 30px;
}
.cx--cover-container .cx--cover-body-wrapper .cx--cover-title {
  font-size: 50px;
  color: #222F3E;
  line-height: 1.1;
}
.cx--cover-container .cx--cover-body-wrapper .cx--cover-text {
  color: #222F3E;
  opacity: 0.8;
  font-size: 20px;
  line-height: 1.5;
  max-width: 750px;
  margin: 0;
}
@media only screen and (min-width: 1200px) {
  .cx--cover-container .cx--cover-body-wrapper .cx--cover-title {
    font-size: 60px;
  }
}
@media (max-width: 1199.98px) and (min-width: 768px) {
  .cx--cover-container .cx--cover-body-wrapper .cx--cover-title {
    font-size: 70px;
  }
}
.cx--cover-container .cx--cover-body-wrapper .cx--cover-form {
  padding: 20px 30px;
  box-shadow: 0 0 30px rgba(5, 51, 179, 0.1);
  background-color: #FFFFFF;
  border-radius: 10px;
}
.cx--cover-container .cx--cover-body-wrapper .cx--cover-form .cx--cover-form-title {
  color: #222F3E;
  text-transform: capitalize;
}
.cx--cover-container .cx--cover-body-wrapper .cx--cover-form .cx--cover-sub-title {
  color: #222F3E;
  font-size: 14px;
}
.cx--cover-container .cx--cover-body-wrapper .cx--cover-form .cx--cover-sub-title a {
  color: #0533B3;
  text-transform: capitalize;
}
@media (min-width: 768px) {
  .cx--cover-container .cx--cover-body-wrapper {
    padding-bottom: 60px;
    padding-top: 220px;
  }
}
.cx--cover-container .cx--cover-image {
  display: none;
  text-align: center;
}
@media (min-width: 992px) {
  .cx--cover-container .cx--cover-image {
    display: block;
  }
  .cx--cover-container .cx--cover-image img {
    width: 100%;
    margin: 0 auto;
    max-width: 570px;
  }
}

.cx--cover-bottom-container .cx--cover-bottom-title-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.cx--cover-bottom-container .cx--cover-bottom-title-wrapper .cx--cover-bottom-title {
  color: #FFFFFF;
  margin-left: 15px;
}
.cx--cover-bottom-container .cx--cover-bottom-title-wrapper .cx--cover-bottom-title h3 {
  font-size: 18px;
  color: #FFFFFF;
  letter-spacing: 2px;
  margin: 0;
  text-transform: uppercase;
}
.cx--cover-bottom-container .cx--cover-bottom-title-wrapper .cx--cover-bottom-title p {
  margin: 0;
}

@media (min-width: 992px) {
  .cx--cover-bottom-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .cx--cover-bottom-container .cx--cover-bottom-title-wrapper {
    margin: 0;
  }
  .cx--cover-bottom-container .cx--cover-bottom-title-wrapper .cx--cover-bottom-title h3 {
    font-size: 24px !important;
  }
}
.cx--contact-info-wrapper strong {
  color: #222F3E !important;
}

.container {
  padding-right: 10px;
  padding-left: 10px;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px !important;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px !important;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px !important;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px !important;
  }
}
@media (min-width: 1500px) {
  .container {
    max-width: 1440px !important;
  }
}
.row {
  margin-right: -10px;
  margin-left: -10px;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  padding-right: 10px;
  padding-left: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

.word-break-break-word {
  word-break: break-word;
}

.text-underline {
  text-decoration: underline;
}

.list-style-disc {
  list-style-type: disc;
}

.list-style-circle {
  list-style-type: circle;
}

.border-none {
  border: none !important;
}

.text-initial {
  text-transform: initial !important;
}

.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.business-form {
  border: 1px solid #E5E5E5;
  border-radius: 5px;
}
.business-form .tablinks {
  display: block;
  background-color: inherit;
  padding: 22px 16px;
  width: 100%;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 3px;
  border: 1px solid #0533B3;
  color: #0533B3;
}
.business-form .tablinks:hover {
  background-color: #063acc;
  color: #FFFFFF;
  transition: 0.3s;
}
.business-form .left-section {
  border-right: 1px solid #E5E5E5;
}
.business-form .cryllex-select__control {
  height: 55px;
}
.business-form .tab button.active {
  background-color: #0533B3;
  color: #FFFFFF;
}
.business-form .tabcontent {
  padding: 10px;
}
.business-form .logo-image-block {
  height: 200px;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #E5E5E5;
  background-position-x: center;
  background-position-y: center;
}
@media (max-width: 768px) {
  .business-form .left-section {
    border-right: none;
    border-bottom: 1px solid #E5E5E5;
  }
}

.scroll-top {
  position: fixed;
  right: 20px;
  bottom: 20px;
  padding: 10px;
  border-radius: 10px;
  box-sizing: content-box;
  background-color: #0533B3;
  z-index: 1002;
  cursor: pointer;
  box-shadow: 0 0 2px 0 #FFFFFF;
}
.scroll-top img {
  width: 30px;
}

.modal-dialog {
  margin-bottom: 0rem;
  max-width: 100%;
}
.modal-dialog .modal-content {
  border-radius: 0rem;
}
.modal-dialog .buttons .btn {
  margin: 0.2rem;
}

.cookie-modal .cookie-modal-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFFFFF;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1003;
}
.cookie-modal .cookie-modal-block button {
  margin: 3px;
}
.cookie-modal:after {
  content: " ";
  background: #000000;
  opacity: 0.4;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1002;
}

.cryllex-accordion-left-menu .accordion-item {
  background-color: transparent;
  border: none;
}
.cryllex-accordion-left-menu .accordion-item .accordion-header {
  position: relative;
  transition: 0.2s;
}
.cryllex-accordion-left-menu .accordion-item .accordion-header button {
  padding: 0.6em 1.5em;
  background-color: transparent;
  font-size: 18px;
}
.cryllex-accordion-left-menu .accordion-item .accordion-header button:focus {
  outline: none;
  border: none;
  box-shadow: none;
}
.cryllex-accordion-left-menu .accordion-item .accordion-header:hover {
  margin-left: 10px;
}
.cryllex-accordion-left-menu .accordion-item .accordion-header:hover button {
  color: #0533B3;
}
.cryllex-accordion-left-menu .accordion-item .accordion-header.active-content {
  margin-left: 18px;
}
.cryllex-accordion-left-menu .accordion-item .accordion-header.active-content button {
  color: #0533B3;
}
.cryllex-accordion-left-menu .accordion-item .accordion-header.active-content::after {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  background-color: #0533B3;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  left: 5px;
  line-height: 0;
}
.cryllex-accordion-left-menu .accordion-item .without-arrow button::after {
  display: none !important;
}
.cryllex-accordion-left-menu .accordion-item .cryllex-nav-accordion-body {
  padding-top: 0;
}
.cryllex-accordion-left-menu .accordion-item .cryllex-nav-accordion-body .cryllex-nav-burger-item {
  margin: 7px 0;
}
.cryllex-accordion-left-menu .accordion-collapse {
  margin-left: 20px;
}

.card-spinner {
  position: absolute !important;
  left: 0;
}

.custom-select .cryllex-select__control {
  height: 62px;
  padding-left: 9px;
  padding-right: 9px;
}
.custom-select .cryllex-select__control .cryllex-select__single-value {
  color: #5d6164;
}

.create-prganization-modal .modal-content {
  border-radius: 10px;
  padding: 30px 50px;
}

@media (max-width: 768px) {
  .cookie-modal .cookie-modal-block {
    flex-flow: column;
  }
}
.react-tel-input .flag-dropdown {
  z-index: 1;
}
.react-tel-input .form-control:focus {
  box-shadow: none !important;
}
.react-tel-input input {
  width: inherit !important;
  height: inherit !important;
}

.font, .font-Black, .cx-float-wrapper .cx--card-icon span, .font-ExtraBold, .font-Bold, .cx--card-wrapper .cx--card-title, .cx--bordered-card-wrapper .cx--card-title, .cx--section-title, .cx--cover-container .cx--cover-body-wrapper .cx--cover-title, .cx--cover-container .cx--cover-body-wrapper .cx--cover-form .cx--cover-form-title, .font-Medium, .cx--list-wrapper .cx--list .cx--list-item h6, header .dropdown .nav-link, .font-default, body, .cx--signed-customer-wrapper .cx--signed-nav-link .cx--signed-user-modal li a, .font-Light, .cx--section-subtitle, .font-Thin {
  font-family: "MADE TOMMY" !important;
  font-style: normal;
  font-display: swap;
}

.font-Thin {
  font-weight: 100;
}

.font-Light, .cx--section-subtitle {
  font-weight: 300;
}

.font-default, body, .cx--signed-customer-wrapper .cx--signed-nav-link .cx--signed-user-modal li a {
  font-weight: normal;
}

.font-Medium, .cx--list-wrapper .cx--list .cx--list-item h6, header .dropdown .nav-link {
  font-weight: 500;
}

.font-Bold, .cx--card-wrapper .cx--card-title, .cx--bordered-card-wrapper .cx--card-title, .cx--section-title, .cx--cover-container .cx--cover-body-wrapper .cx--cover-title, .cx--cover-container .cx--cover-body-wrapper .cx--cover-form .cx--cover-form-title {
  font-weight: 700;
}

.font-ExtraBold {
  font-weight: bold;
}

.font-Black, .cx-float-wrapper .cx--card-icon span {
  font-weight: 900;
}

.flex-1 {
  flex: 1;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-size: 16px;
}

@media (min-width: 992px) {
  body {
    font-size: 18px !important;
  }
}
.cx--white-line {
  background-color: #FFFFFF;
}

.cx--section {
  padding: 60px 0;
}

.cx--sm-section {
  padding: 40px 0;
}

@media (min-width: 992px) {
  .cx--section {
    padding: 60px 0;
  }
  .cx--sm-section {
    padding: 50px 0;
  }
}
.cx--section-title {
  font-size: 30px;
  text-transform: capitalize;
}
.cx--section-title span {
  color: #0533B3;
}

.cx--section-title.cx--section-dark-title {
  color: #222F3E;
}

.cx--section-title.cx--section-light-title {
  color: #FFFFFF;
}

.cx--text-mute {
  opacity: 0.7;
}

.cx--text-transform-initial {
  text-transform: initial;
}

.cx--section-subtitle {
  opacity: 0.8;
  font-size: 16px;
  font-weight: 400;
}

.cx--section-dark-subtitle {
  color: #222F3E;
}

.cx--section-light-subtitle {
  color: #FFFFFF;
}

@media (min-width: 992px) {
  .cx--section-title {
    font-size: 50px;
  }
  .cx--section-subtitle {
    font-size: 22px;
  }
}
.cx--primary-color {
  color: #0533B3 !important;
}

.cx--secondary-color {
  color: #F9AE30 !important;
}

.cx--success-color {
  color: #05B32E !important;
}

.cx--success-color {
  color: #05B32E !important;
}

.cx--white-color {
  color: #FFFFFF;
}

.cx--section-decoration {
  position: absolute;
  z-index: 0;
  max-width: 150px;
}

.cx--section-decoration-left {
  left: 10px;
}

.cx--section-decoration-right {
  right: 10px;
}

.cx--section-decoration-top {
  top: 10px;
}

.cx--section-decoration-top-right {
  top: -60px;
  right: 10px;
}

.cx--section-decoration-bottom {
  bottom: -55px;
}

.cx--section-decoration-bottom-right {
  left: 25px;
  bottom: -50px;
  z-index: -1;
}

@media (min-width: 992px) {
  .cx--section-decoration-bottom-right {
    left: auto;
    right: -65px;
  }
}
.cx--list-wrapper {
  list-style: none;
  padding: 0;
}
.cx--list-wrapper .cx--list {
  display: flex;
  align-items: flex-start;
  margin: 5px 0;
}
.cx--list-wrapper .cx--list img {
  display: block;
  max-width: 100%;
  margin-right: 15px;
  margin-top: 5px;
  width: 18px;
}
.cx--list-wrapper .cx--list .cx--list-item h6 {
  line-height: 30px;
  font-size: 18px;
}
.cx--list-wrapper .cx--list .cx--list-item p {
  font-size: 16px;
  line-height: 1.4;
  opacity: 0.7;
}
.cx--list-wrapper .cx--large-list {
  margin: 25px 0;
}

@media (min-width: 992px) {
  .cx--list-wrapper .cx--list .cx--list-item h6 {
    font-size: 24px;
  }
  .cx--list-wrapper .cx--list .cx--list-item p {
    font-size: 20px;
  }
}
.cx--section-image-wrapper {
  max-width: 100%;
  text-align: center;
  margin-bottom: 35px;
}
.cx--section-image-wrapper img {
  width: 100%;
}

@media (min-width: 768px) {
  .cx--section-image-wrapper {
    margin-bottom: 0;
  }
  .cx--section-image-wrapper img {
    max-width: 450px;
  }
}
.cx--card-wrapper, .cx--bordered-card-wrapper {
  padding: 20px;
  background-color: #FFFFFF;
  border-radius: 10px;
  transition: all 0.3s;
  height: 100%;
}
.cx--card-wrapper .cx--card-title-wrapper, .cx--bordered-card-wrapper .cx--card-title-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.cx--card-wrapper .cx--card-title-wrapper .cx--card-icon, .cx--bordered-card-wrapper .cx--card-title-wrapper .cx--card-icon {
  height: 70px;
  width: 70px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E3EDFA;
}
.cx--card-wrapper .cx--card-title-wrapper .cx--card-icon img, .cx--bordered-card-wrapper .cx--card-title-wrapper .cx--card-icon img {
  max-width: 30px;
  padding: 10px 20px;
  box-sizing: content-box;
}
.cx--card-wrapper .cx--card-title, .cx--bordered-card-wrapper .cx--card-title {
  margin: 0;
  margin-left: 10px;
  font-size: 18px;
}
.cx--card-wrapper .cx--card-large-title, .cx--bordered-card-wrapper .cx--card-large-title {
  text-transform: uppercase;
  font-size: 20px;
}
@media (min-width: 768px) {
  .cx--card-wrapper .cx--card-title, .cx--bordered-card-wrapper .cx--card-title {
    font-size: 20px;
  }
  .cx--card-wrapper .cx--card-large-title, .cx--bordered-card-wrapper .cx--card-large-title {
    font-size: 22px;
  }
}

.cx-float-wrapper {
  padding-left: 10px;
  padding-right: 10px;
}
.cx-float-wrapper .cx--card-icon {
  float: left;
  height: 70px;
  width: 70px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E3EDFA;
  margin-right: 15px;
}
.cx-float-wrapper .cx--card-icon span {
  font-size: 30px;
  color: #0533B3;
}
.cx-float-wrapper .cx--card-body {
  line-height: 1.5;
  text-align: justify;
}

@media screen and (min-width: 768px) {
  .cx--card.cx--card-wrapper:hover, .cx--card.cx--bordered-card-wrapper:hover {
    transform: scale(1.02);
    transform-origin: right right;
  }
}
.cx--bordered-card-wrapper {
  border: 1px solid #0533B3;
  min-height: 230px;
}

.cx--horizontal-list {
  color: #FFFFFF;
  margin-bottom: 25px;
}
.cx--horizontal-list strong {
  display: block;
  font-size: 30px;
}
.cx--horizontal-list span {
  opacity: 0.5;
}

.cx--partners-slider .cx--partner a {
  display: block;
}
.cx--partners-slider .cx--partner a img {
  width: 100%;
  max-height: 35px;
  opacity: 0.5;
  transition: all 0.3s;
}
.cx--partners-slider .cx--partner a img:hover {
  opacity: 1;
}

@media (min-width: 1200px) {
  .cx--horizontal-list {
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .cx--horizontal-list img {
    max-height: 45px;
  }
  .cx--horizontal-list strong {
    font-size: 45px;
  }
}
.cx--states {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3em;
}
.cx--states .cx--card-wrapper, .cx--states .cx--bordered-card-wrapper {
  flex: 0 1 50%;
  text-align: center;
  border-radius: 0;
  min-height: 230px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.cx--states .cx--card-wrapper .cx--card-title-wrapper, .cx--states .cx--bordered-card-wrapper .cx--card-title-wrapper {
  justify-content: center;
}
.cx--states .cx--card-wrapper .cx--card-title-wrapper img, .cx--states .cx--bordered-card-wrapper .cx--card-title-wrapper img {
  max-height: 50px;
}
@media (max-width: 991.98px) {
  .cx--states .cx--card-wrapper:nth-child(1), .cx--states .cx--bordered-card-wrapper:nth-child(1), .cx--states .cx--card-wrapper:nth-child(3), .cx--states .cx--bordered-card-wrapper:nth-child(3), .cx--states .cx--card-wrapper:nth-child(5), .cx--states .cx--bordered-card-wrapper:nth-child(5) {
    border-right: 1px solid #0533B3;
  }
  .cx--states .cx--card-wrapper:nth-child(1), .cx--states .cx--bordered-card-wrapper:nth-child(1), .cx--states .cx--card-wrapper:nth-child(2), .cx--states .cx--bordered-card-wrapper:nth-child(2), .cx--states .cx--card-wrapper:nth-child(3), .cx--states .cx--bordered-card-wrapper:nth-child(3), .cx--states .cx--card-wrapper:nth-child(4), .cx--states .cx--bordered-card-wrapper:nth-child(4) {
    border-bottom: 1px solid #0533B3;
  }
}
@media screen and (min-width: 992px) {
  .cx--states .cx--card-wrapper, .cx--states .cx--bordered-card-wrapper {
    flex: 0 1 33%;
  }
  .cx--states .cx--card-wrapper .cx--card-title-wrapper img, .cx--states .cx--bordered-card-wrapper .cx--card-title-wrapper img {
    max-height: 70px;
  }
  .cx--states .cx--card-wrapper:nth-child(1), .cx--states .cx--bordered-card-wrapper:nth-child(1), .cx--states .cx--card-wrapper:nth-child(2), .cx--states .cx--bordered-card-wrapper:nth-child(2), .cx--states .cx--card-wrapper:nth-child(4), .cx--states .cx--bordered-card-wrapper:nth-child(4), .cx--states .cx--card-wrapper:nth-child(5), .cx--states .cx--bordered-card-wrapper:nth-child(5) {
    border-right: 1px solid #0533B3;
  }
  .cx--states .cx--card-wrapper:nth-child(1), .cx--states .cx--bordered-card-wrapper:nth-child(1), .cx--states .cx--card-wrapper:nth-child(2), .cx--states .cx--bordered-card-wrapper:nth-child(2), .cx--states .cx--card-wrapper:nth-child(3), .cx--states .cx--bordered-card-wrapper:nth-child(3) {
    border-bottom: 1px solid #0533B3;
  }
}

.cx--wordwide {
  text-align: center;
}
.cx--wordwide img {
  width: 100%;
}
@media (min-width: 992px) {
  .cx--wordwide img {
    width: 90%;
  }
}

.cx--relamy-copyright {
  padding: 10px 0;
  background-color: #CCCCCC;
  text-align: center;
}

.cx--aside-image {
  width: 100%;
  max-width: 400px;
}

@media screen and (min-width: 1200px) {
  .cx--aside-image {
    max-width: 500px;
  }
}
.cx--section-title-sm {
  font-size: 28px;
  color: #0533B3;
  display: block;
}

.cx--vertical-line {
  width: 1px;
  display: inline-block;
  background-color: #FFFFFF;
}/*# sourceMappingURL=styles.css.map */