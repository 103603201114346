$primary-color: #0533B3;
$primary-color-50: #E3EDFA;
$secondary-color: #F9AE30;
$secondary-color-50: #88E1FD;

$dark-color: #222F3E;
$dark-color-500: #5d6164;
$dark-color-300: #B2B2B2;
$dark-color-200: #CCCCCC;
$dark-color-100: #E5E5E5;
$body-color-100: #F9F9F9;

$white-color: #FFFFFF;
$white-color-100: #ffffff52;

$black-color: #000000;

$success-color: #05B32E;
$danger-color: #B30505;
$warning-color: #ffcc00;


$background: linear-gradient(60deg, rgba(0,31,117,1) 0%, rgba(5,51,179,1) 40%, rgba(19,196,252,1) 100%);

$font-size-10: 10px;
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-22: 22px;
$font-size-24: 24px;
$font-size-26: 26px;
$font-size-28: 28px;
$font-size-30: 30px;
$font-size-40: 40px;
$font-size-45: 45px;
$font-size-50: 50px;
$font-size-60: 60px;
$font-size-70: 70px;
$font-size-90: 90px;

$border-radius-5: 5px;
$border-radius-10: 10px;
$border-radius-15: 15px;
$border-radius-20: 20px;
$border-radius-50: 50px;