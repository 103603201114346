.cursor-pointer {
  cursor: pointer;
}

.word-break-break-word {
  word-break: break-word
}

.text-underline {
  text-decoration: underline;
}

.list-style-disc {
  list-style-type: disc;
}

.list-style-circle {
  list-style-type: circle;
}

.border-none {
  border: none !important;
}

.text-initial {
  text-transform: initial !important;
}

.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.business-form {
  border: 1px solid $dark-color-100;
  border-radius: 5px;

  .tablinks {
    display: block;
    background-color: inherit;
    padding: 22px 16px;
    width: 100%;
    outline: none;
    text-align: left;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 3px;
    border: 1px solid $primary-color;
    color: $primary-color;

    &:hover {
      background-color: lighten($primary-color, 5%);
      color: $white-color;
      transition: 0.3s;
    }
  }

  .left-section {
    border-right: 1px solid $dark-color-100;
  }

  .cryllex-select__control {
    height: 55px;
  }

  .tab button.active {
    background-color: $primary-color;
    color: $white-color;
  }

  .tabcontent {
    padding: 10px;
  }

  .logo-image-block {
    height: 200px;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: $dark-color-100;
    background-position-x: center;
    background-position-y: center;
  }

  @media (max-width: 768px) {
    .left-section {
      border-right: none;
      border-bottom: 1px solid $dark-color-100;
    }
  }
}

.scroll-top {
  position: fixed;
  right: 20px;
  bottom: 20px;
  padding: 10px;
  border-radius: $border-radius-10;
  box-sizing: content-box;
  background-color: $primary-color;
  z-index: 1002;
  cursor: pointer;
  box-shadow: 0 0 2px 0 $white-color;

  img {
    width: 30px;
  }
}

.modal-dialog {
  margin-bottom: 0rem;
  max-width: 100%;

  .modal-content {
    border-radius: 0rem;
  }

  .buttons {
    .btn {
      margin: 0.2rem;
    }
  }
}

.cookie-modal {
  .cookie-modal-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $white-color;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1003;

    button {
      margin: 3px;
    }
  }

  &:after {
    content: " ";
    background: $black-color;
    opacity: 0.4;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1002;
  }
}

.cryllex-accordion-left-menu {

  .accordion-item {
    background-color: transparent;
    border: none;

    .accordion-header {
      position: relative;
      transition: 0.2s;

      button {
        padding: 0.6em 1.5em;
        background-color: transparent;
        font-size: $font-size-18;

        &:focus {
          outline: none;
          border: none;
          box-shadow: none;
        }
      }

      &:hover {
        button {
          color: $primary-color;
        }

        margin-left: 10px;
      }

      &.active-content {
        button {
          color: $primary-color;
        }

        &::after {
          content: "";
          position: absolute;
          width: 7px;
          height: 7px;
          background-color: $primary-color;
          border-radius: 50%;
          top: 50%;
          transform: translateY(-50%);
          left: 5px;
          line-height: 0;
        }

        margin-left: 18px;
      }

    }

    .without-arrow {
      button {
        &::after {
          display: none !important;
        }
      }
    }

    .cryllex-nav-accordion-body {
      padding-top: 0;

      .cryllex-nav-burger-item {
        margin: 7px 0;
      }
    }
  }

  .accordion-collapse {
    margin-left: 20px;
  }
}

.card-spinner {
  position: absolute !important;
  left: 0;
}

.custom-select {
  .cryllex-select__control {
    height: 62px;
    padding-left: 9px;
    padding-right: 9px;

    .cryllex-select__single-value {
      color: $dark-color-500;
    }
  }
}

.create-prganization-modal {
  .modal-content {
    border-radius: 10px;
    padding: 30px 50px;
  }
}

@media (max-width: 768px) {
  .cookie-modal {
    .cookie-modal-block {
      flex-flow: column;
    }
  }
}

.react-tel-input {

  .flag-dropdown {
    z-index: 1;
  }

  .form-control {
    &:focus {
      box-shadow: none !important;
    }
  }

  input {
    width: inherit !important;
    height: inherit !important;
  }
}