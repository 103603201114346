input[type="text"],
input[type="textarea"],
input[type="number"],
input[type="tel"],
input[type="email"],
input[type="password"],
input[type="date"],
input[type="datetime"],
textarea {
    padding: 18px 20px;
    border-radius: $border-radius-5;
    outline: none;
    font-weight: normal;
    border: 1px solid $dark-color-200;
    width: 100%;
    position: relative;
    background-color: transparent;
    z-index: 1;
    font-size: $font-size-16;

    &::placeholder {
        transition: all 0.3s;
        opacity: 0;
    }

    &:focus,
    &:active {
        border-color: $dark-color-300;

        &::placeholder {
            opacity: 0.7;
        }
    }

    &:-webkit-autofill {
        box-shadow: 0 0 0 30px white inset !important;
    }

    &:focus~span,
    &:not(:placeholder-shown)~span {
        opacity: 0.6;
        transform: translateY(0);
        font-size: $font-size-14;
        z-index: 2;
        opacity: 1;
        top: -10px;
        background-color: $white-color;
        padding: 1px 8px;
        border-radius: 3px;
    }
}

.cx--form-control {
    position: relative;
    margin-bottom: 2rem;

    span {
        position: absolute;
        color: $dark-color-500;
        left: 20px;
        top: 18px;
        transition: all 0.3s;
        font-size: $font-size-16;
        text-transform: capitalize;
        // z-index: 2;
    }
}

@media (min-width: 768px) {
    input {
        font-size: $font-size-18;
    }

    .cx--form-control {
        span {
            font-size: $font-size-18;
        }
    }
}

.react-tel-input {
    input {
        &:focus {
            .custom-phone-number-input-label {
                opacity: 0.6;
                transform: translateY(0);
                font-size: $font-size-14;
                z-index: 2;
                opacity: 1;
                top: -10px;
                background-color: $white-color;
                padding: 1px 8px;
                border-radius: 3px;
            }
        }
    }
}

.cx--auth-control {
    input {
        background-color: transparent;
        border: 1px solid $white-color-100;
        color: $white-color;

        &::placeholder {
            color: $white-color !important;
        }

        &:focus {
            border-color: $secondary-color-50;

            &::placeholder {
                color: $white-color;
            }
        }
    }

    span {
        color: $white-color;
    }
}

.select-fail {
    .cryllex-select__control {
        border-color: $danger-color !important;
    }
}

.cx--danger-border {
    border-color: $danger-color !important;
}

.cx--danger-border-2 {
    border: 2px solid $danger-color !important;
}

.cx--success-border {
    border-color: $success-color !important;
}

.cx--warning-border {
    border-color: $warning-color !important;
}