@font-face {
    font-family: 'MADE TOMMY';
    src: url('../fonts/MADETOMMY-Medium.eot');
    src: url('../fonts/MADETOMMY-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MADETOMMY-Medium.woff2') format('woff2'),
        url('../fonts/MADETOMMY-Medium.woff') format('woff'),
        url('../fonts/MADETOMMY-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MADE TOMMY';
    src: url('../fonts/MADETOMMY-Black.eot');
    src: url('../fonts/MADETOMMY-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MADETOMMY-Black.woff2') format('woff2'),
        url('../fonts/MADETOMMY-Black.woff') format('woff'),
        url('../fonts/MADETOMMY-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MADE TOMMY';
    src: url('../fonts/MADETOMMY-ExtraBold.eot');
    src: url('../fonts/MADETOMMY-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MADETOMMY-ExtraBold.woff2') format('woff2'),
        url('../fonts/MADETOMMY-ExtraBold.woff') format('woff'),
        url('../fonts/MADETOMMY-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MADE TOMMY';
    src: url('../fonts/MADETOMMY-Light.eot');
    src: url('../fonts/MADETOMMY-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MADETOMMY-Light.woff2') format('woff2'),
        url('../fonts/MADETOMMY-Light.woff') format('woff'),
        url('../fonts/MADETOMMY-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MADE TOMMY';
    src: url('../fonts/MADETOMMY-Bold.eot');
    src: url('../fonts/MADETOMMY-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MADETOMMY-Bold.woff2') format('woff2'),
        url('../fonts/MADETOMMY-Bold.woff') format('woff'),
        url('../fonts/MADETOMMY-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MADE TOMMY';
    src: url('../fonts/MADETOMMY.eot');
    src: url('../fonts/MADETOMMY.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MADETOMMY.woff2') format('woff2'),
        url('../fonts/MADETOMMY.woff') format('woff'),
        url('../fonts/MADETOMMY.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MADE TOMMY';
    src: url('../fonts/MADETOMMY-Thin.eot');
    src: url('../fonts/MADETOMMY-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MADETOMMY-Thin.woff2') format('woff2'),
        url('../fonts/MADETOMMY-Thin.woff') format('woff'),
        url('../fonts/MADETOMMY-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}


