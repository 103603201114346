.cx--primary-background{
    background-color: $primary-color;
}

.cx--dark-background{
    background-color: $dark-color;
}

.cx--gray-background{
    background-color: $body-color-100;
}