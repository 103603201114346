.spinner-layer {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: $white-color;
  top: 0;
  z-index: 1002;
  opacity: 0.9;

  .sweet-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
