header {
    position: fixed;
    width: 100%;
    padding: 15px 0;
    z-index: 100;
    transition: all 0.3s;
    top: 0;

    .dropdown {
        min-width: 200px !important;

        .nav-link {
            color: $primary-color !important;
            font-size: $font-size-16;
            padding: 10px 15px !important;
            text-transform: capitalize;
            transition: all 0.2s;
            @extend .font-Medium;

            // &:hover:not(:last-child),
            // &:focus:not(:last-child) {
            //     opacity: 0.6;
            //     color: $primary-color;
            // }
            &:hover {
                opacity: 0.6;
                color: $primary-color;
            }

            // &:last-child {
            //     border: 1px solid $primary-color;
            //     border-radius: $border-radius-5;
            //     padding: 10px 30px !important;
            //     margin-left: 15px;

            //     &:hover {
            //         background-color: $primary-color;
            //         color: $white-color;
            //     }
            // }
        }
    }

    .navbar-brand {
        display: flex;

        img {
            max-height: 30px;
        }
    }
}

header.cx--header-active {
    padding: 5px 0;
    background-color: $white-color;
    box-shadow: 0 5px 15px rgba($dark-color, 0.2);
}

@media (min-width: 768px) {
    header {
        padding: 50px 0;

        .navbar-brand {
            img {
                max-height: 35px;
            }
        }
    }

    header.cx--header-active {
        padding: 10px 0;
    }
}

.header-profile-user-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid $primary-color;
}

.header-profile-user {
    color: $white-color;
    border-radius: 50%;
    padding: 10px;
    background-color: $primary-color;
}

.cx--burger-menu-wrapper {
    position: relative;
    z-index: 5;

    input {
        display: block;
        width: 40px;
        height: 40px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
        opacity: 0;
        z-index: 15;
        -webkit-touch-callout: none;

        &:checked~span {
            opacity: 1;
            transform: rotate(45deg) translate(4px, -6px);
            background-color: $primary-color;
        }

        &:checked~span:nth-last-child(3) {
            opacity: 0;
            transform: rotate(0deg) scale(0.2, 0.2);
        }

        &:checked~span:nth-last-child(2) {
            transform: rotate(-45deg) translate(0px, 10px);
            background-color: $primary-color;
        }
    }

    span {
        display: block;
        width: 40px;
        height: 3px;
        margin: 5px 0;
        position: relative;
        right: 0;
        background: $primary-color;
        border-radius: 3px;
        z-index: 1;
        transform-origin: 4px 0px;
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
            background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

        &:first-child {
            transform-origin: 0% 0%;
        }

        &:nth-last-child(2) {
            transform-origin: 0% 100%;
        }
    }

    .cx--burger-menu {
        position: fixed;
        width: 100%;
        max-width: 400px;
        height: 100%;
        margin: 0;
        padding: 50px;
        text-align: center;
        right: 0;
        top: 0;
        background: $white-color;
        overflow-y: auto;
        -webkit-font-smoothing: antialiased;
        box-shadow: 0 -5px 15px rgba($dark-color, 0.2);

        transform-origin: 0% 0%;
        transform: translate(500px, 0);

        transition: transform 0.55s cubic-bezier(0.77, 0.2, 0.05, 1);

        .cx--signed-customer {
            text-align: center;
            margin-top: 15px;

            .cx--customer-avatar {
                width: 75px;
                height: 75px;

                .signed-avatar {
                    width: 70px;
                    height: 70px;
                }
            }

            .cx--signed-cutomer-name {
                margin-top: 10px;
                margin-bottom: 0;
                color: $dark-color;
                display: block;
                font-size: $font-size-20;
                text-transform: capitalize;

                &:hover {
                    color: $primary-color;
                    text-decoration: none;
                }
            }
        }

        ul {
            list-style-type: none;
            padding: 0;
            margin-top: 15px;

            .cx--nav-burger-item {
                text-align: right;

                a {
                    border: 1px solid transparent;
                    display: block;
                    font-size: $font-size-16;
                    text-decoration: none;
                    text-transform: capitalize;
                    color: $primary-color;
                    font-weight: 500;
                    transition: all 0.2s;
                    padding: 4px 0;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }

            li.active a {
                opacity: 0.8;
            }
        }
    }

    input:checked~.cx--burger-menu {
        transform: none;
    }

    .cx--unsigned-customer {
        ul {
            margin-top: 80px;
        }
    }
}


@media screen and (min-width: 1200px) {
    .cx--burger-menu-wrapper {
        display: none;
    }
}

@media (max-width: 1199.98px) {
    .cx--screen-menu {
        display: none;
    }
}



.cx--signed-customer-wrapper {
    display: flex !important;
    padding: 10px 0;
    align-items: center;

    .cx--signed-nav-link {
        padding: 0 .75rem !important;
        position: relative;

        input {
            display: block;
            width: 40px;
            height: 40px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
            opacity: 0;
            z-index: 15;
            -webkit-touch-callout: none;
        }

        input:checked~.cx--signed-user-modal,
        input:checked~.burgers {
            display: block;
        }

        .cx--customer-avatar {
            cursor: pointer;
            width: 40px;
            height: 40px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: $border-radius-50;
            box-shadow: 0 0 3px rgba($dark-color, 0.4);

            .signed-avatar {
                width: 35px;
                height: 35px;
                border-radius: $border-radius-50;
                border: 1px solid $dark-color-100;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }
        }

        .cx--signed-user-modal {
            display: none;
            position: absolute;
            list-style: none;
            min-width: 200px;
            right: 0;
            padding: 0;
            margin: 8px 0;
            background-color: $white-color;
            text-align: right;
            border-radius: $border-radius-5;
            box-shadow: 0 0 10px rgba($dark-color, 0.2);

            li {
                margin: 5px 0;

                .cx--signed-cutomer-name {
                    font-size: $font-size-16;
                    padding: 10px 20px;
                    line-height: 1;
                    font-weight: 500;
                    margin: 0;
                    white-space: nowrap;

                    &:hover {
                        color: $primary-color;
                        text-decoration: none;
                    }
                }

                a {
                    font-size: $font-size-16;
                    display: block;
                    padding: 5px 20px;
                    color: $dark-color;
                    text-decoration: none;
                    @extend .font-default;

                    &:hover {
                        color: $primary-color;
                        text-decoration: none;
                    }

                    &::first-letter {
                        text-transform: capitalize;
                    }
                }
            }

            .hr {
                border-bottom: 1px solid $dark-color-100;
            }

            // display: none;
        }
    }


}

.header {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;

    li {
        position: relative;
        padding: 10px 20px;
        white-space: nowrap;
        color: $primary-color;
        transition: 0.3s;

        &:hover {
            cursor: pointer;
            transform: scale(1.03);
        }

        .main-link {
            text-decoration: none;
            color: $primary-color;
        }

        .dropdown {
            display: none;
            position: absolute;
            top: 100%;
            // left: 0;
            left: 50%;
            transform: translateX(-50%);
            width: auto;
            padding: 10px 0;
            border-radius: $border-radius-5;
            background: $white-color;
            box-shadow: 0px 2px 20px rgba($color: $primary-color, $alpha: 0.15);
            z-index: 1;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;

            li {
                padding: 10px;
                list-style: none;

                a {
                    padding: 0 10px !important;
                    text-decoration: none;
                }
            }
        }

        &:hover .dropdown {
            display: block;
            opacity: 1;
        }
    }
}

.cx--nav-accordion {
    .accordion-item {
        border: none;
    }

    .button-without-icon {
        button {
            &::before {
                display: none !important;
            }
        }
    }

    .cx--nav-accordion-header {

        button {
            display: flex;
            justify-content: end;
            align-items: center;
            border-top: 1px solid $dark-color-100;
            padding: 15px !important;

            &:not(.collapsed) {
                background-color: transparent;
                box-shadow: none;
            }

            &::after {
                display: none;
            }

            &:focus {
                outline: none;
                box-shadow: none;
            }

            &:not(.collapsed)::before {
                background-image: var(--bs-accordion-btn-active-icon);
                transform: var(--bs-accordion-btn-icon-transform);
            }

            &::before {
                flex-shrink: 0;
                width: var(--bs-accordion-btn-icon-width);
                height: var(--bs-accordion-btn-icon-width);
                margin-right: auto;
                content: "";
                background-image: var(--bs-accordion-btn-icon);
                background-repeat: no-repeat;
                background-size: var(--bs-accordion-btn-icon-width);
                transition: var(--bs-accordion-btn-icon-transition);
            }

            &.collapsed {
                border-radius: 5px;
                align-items: center;
            }
        }
    }

    .cx--nav-accordion-body {
        padding: 20px 30px 10px;
        background-color: lighten($color: rgba($dark-color, 0.2), $amount: 70);
        border-radius: $border-radius-5;
        margin-bottom: 10px;
    }
}

.right-0 {
    right: 0;
}

.left-auto {
    left: auto !important;
}