@import "app";

.font {
    font-family: 'MADE TOMMY' !important;
    font-style: normal;
    font-display: swap;
}

.font-Thin {
    font-weight: 100;
    @extend .font;
}

.font-Light {
    font-weight: 300;
    @extend .font;
}

.font-default {
    font-weight: normal;
    @extend .font;
}

.font-Medium {
    font-weight: 500;
    @extend .font;
}

.font-Bold {
    font-weight: 700;
    @extend .font;
}

.font-ExtraBold {
    font-weight: bold;
    @extend .font;
}

.font-Black {
    font-weight: 900;
    @extend .font;
}

.flex-1 {
    flex: 1;
}

body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-size: $font-size-16;
    @extend .font-default;
}

@media (min-width: 992px) {
    body {
        font-size: $font-size-18 !important;
    }
}

.cx--white-line {
    background-color: $white-color;
}


.cx--section {
    padding: 60px 0;
}

.cx--sm-section {
    padding: 40px 0;
}

@media (min-width: 992px) {
    .cx--section {
        padding: 60px 0;
    }

    .cx--sm-section {
        padding: 50px 0;
    }
}

.cx--section-title {
    font-size: $font-size-30;
    text-transform: capitalize;
    @extend .font-Bold;

    span {
        color: $primary-color;
    }
}

.cx--section-title.cx--section-dark-title {
    color: $dark-color;
}

.cx--section-title.cx--section-light-title {
    color: $white-color;
}

.cx--text-mute {
    opacity: 0.7;
}

.cx--text-transform-initial {
    text-transform: initial;
}

.cx--section-subtitle {
    @extend .font-Light;
    opacity: 0.8;
    font-size: $font-size-16;
    font-weight: 400;
}

.cx--section-dark-subtitle {
    color: $dark-color;
}

.cx--section-light-subtitle {
    color: $white-color;
}

@media (min-width: 992px) {
    .cx--section-title {
        font-size: $font-size-50;
    }

    .cx--section-subtitle {
        font-size: $font-size-22;
    }
}

.cx--primary-color {
    color: $primary-color !important;
}

.cx--secondary-color {
    color: $secondary-color !important;
}

.cx--success-color {
    color: $success-color !important;
}

.cx--success-color {
    color: $success-color !important;
}

.cx--white-color {
    color: $white-color;
}


.cx--section-decoration {
    position: absolute;
    z-index: 0;
    max-width: 150px;
}

.cx--section-decoration-left {
    left: 10px;
}

.cx--section-decoration-right {
    right: 10px;
}

.cx--section-decoration-top {
    top: 10px;
}

.cx--section-decoration-top-right {
    top: -60px;
    right: 10px;
}

.cx--section-decoration-bottom {
    bottom: -55px;
}

.cx--section-decoration-bottom-right {
    left: 25px;
    bottom: -50px;
    z-index: -1;
}

@media (min-width: 992px) {
    .cx--section-decoration-bottom-right {
        left: auto;
        right: -65px;
    }
}

.cx--list-wrapper {
    list-style: none;
    padding: 0;

    .cx--list {
        display: flex;
        align-items: flex-start;
        margin: 5px 0;

        img {
            display: block;
            max-width: 100%;
            margin-right: 15px;
            margin-top: 5px;
            width: 18px;
        }

        .cx--list-item {
            h6 {
                line-height: 30px;
                font-size: $font-size-18;
                @extend .font-Medium;
            }

            p {
                font-size: $font-size-16;
                line-height: 1.4;
                opacity: 0.7;
            }
        }
    }

    .cx--large-list {
        margin: 25px 0;
    }
}

@media (min-width: 992px) {
    .cx--list-wrapper {
        .cx--list {
            .cx--list-item {
                h6 {
                    font-size: $font-size-24;
                }

                p {
                    font-size: $font-size-20
                }
            }
        }
    }
}

.cx--section-image-wrapper {
    max-width: 100%;
    text-align: center;
    margin-bottom: 35px;

    img {
        // max-height: 300px;
        width: 100%;
    }
}

@media (min-width: 768px) {
    .cx--section-image-wrapper {
        margin-bottom: 0;

        img {
            max-width: 450px;
            // max-height: 550px;
        }
    }
}

.cx--card-wrapper {
    padding: 20px;
    background-color: $white-color;
    border-radius: 10px;
    transition: all 0.3s;
    height: 100%;

    .cx--card-title-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .cx--card-icon {
            height: 70px;
            width: 70px;
            border-radius: $border-radius-10;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $primary-color-50;

            img {
                max-width: 30px;
                padding: 10px 20px;
                box-sizing: content-box;
            }

        }
    }


    .cx--card-title {
        margin: 0;
        margin-left: 10px;
        @extend .font-Bold;
        font-size: $font-size-18;
    }

    .cx--card-large-title {
        text-transform: uppercase;
        font-size: $font-size-20;
    }

    @media (min-width: 768px) {
        // .cx--card-body {
        //     display: -webkit-box;
        //     -webkit-line-clamp: 3;
        //     -webkit-box-orient: vertical;
        //     overflow: hidden;
        // }

        .cx--card-title {
            font-size: $font-size-20;
        }

        .cx--card-large-title {
            font-size: $font-size-22;
        }
    }
}

.cx-float-wrapper {
    padding-left: 10px;
    padding-right: 10px;

    .cx--card-icon {
        float: left;
        height: 70px;
        width: 70px;
        border-radius: $border-radius-10;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $primary-color-50;
        margin-right: 15px;

        span {
            font-size: $font-size-30;
            @extend .font-Black;
            color: $primary-color;
        }
    }

    .cx--card-body {
        line-height: 1.5;
        text-align: justify;
    }
}

@media screen and (min-width: 768px) {
    .cx--card.cx--card-wrapper {
        &:hover {
            // border-color: transparent;
            transform: scale(1.02);
            transform-origin: right right;
        }
    }

    // .cx--bordered-card-wrapper {
    //     &:hover {
    //         box-shadow: 0 0 30px rgba($primary-color, $alpha: 0.1);
    //         position: absolute;
    //         height: auto;
    //         z-index: 1;

    //         .cx--card-body {
    //             display: block;
    //         }
    //     }
    // }
}

.cx--bordered-card-wrapper {
    border: 1px solid $primary-color;
    min-height: 230px;
    @extend .cx--card-wrapper;
}

.cx--horizontal-list {
    color: $white-color;
    margin-bottom: 25px;

    strong {
        display: block;
        font-size: $font-size-30;
    }

    span {
        opacity: 0.5;
    }

}

.cx--partners-slider {
    .cx--partner {
        a {
            display: block;

            img {
                width: 100%;
                max-height: 35px;
                opacity: 0.5;
                transition: all 0.3s;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }

}

@media (min-width: 1200px) {
    .cx--horizontal-list {
        margin-bottom: 0;
    }
}

@media (min-width: 992px) {
    .cx--horizontal-list {
        img {
            max-height: 45px;
        }

        strong {
            font-size: $font-size-45;
        }
    }
}

.cx--states {
    display: flex;
    flex-wrap: wrap;
    margin-top: 3em;

    .cx--card-wrapper {
        flex: 0 1 50%;
        text-align: center;
        border-radius: 0;
        min-height: 230px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .cx--card-title-wrapper {
            justify-content: center;

            img {
                max-height: 50px;
            }
        }
    }

    @media (max-width: 991.98px) {
        .cx--card-wrapper {

            &:nth-child(1),
            &:nth-child(3),
            &:nth-child(5) {
                border-right: 1px solid $primary-color;
            }

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
                border-bottom: 1px solid $primary-color;
            }
        }
    }

    @media screen and (min-width: 992px) {
        .cx--card-wrapper {
            flex: 0 1 33%;

            .cx--card-title-wrapper {
                img {
                    max-height: 70px;
                }
            }

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(4),
            &:nth-child(5) {
                border-right: 1px solid $primary-color;
            }

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
                border-bottom: 1px solid $primary-color;
            }
        }

    }
}

.cx--wordwide {
    text-align: center;

    img {
        width: 100%;
    }

    @media (min-width: 992px) {
        img {
            width: 90%;
        }
    }
}

.cx--relamy-copyright {
    padding: 10px 0;
    background-color: $dark-color-200;
    text-align: center;
}

.cx--aside-image {
    width: 100%;
    max-width: 400px;
}

@media screen and (min-width: 1200px) {
    .cx--aside-image {
        max-width: 500px;
    }
}

.cx--section-title-sm {
    font-size: $font-size-28;
    color: $primary-color;
    display: block;
}

.cx--vertical-line{
    width: 1px;
    display: inline-block;
    background-color: $white-color;
}