footer{
    .cx--footer-logo{
        img{
            width: 100%;
            max-width: 220px;
        }
    }
    .cx--footer-title{
        font-size: $font-size-20;
        margin-bottom: 15px;
        display: block;
    }

    ul{
        list-style: none;
        padding: 0;
    }
    
    .cx--footer-list{
        .cx--footer-list-item{
            margin: 10px 0;
            a{
                color: $dark-color;
            }
        }
    }
    
    .cx--footer-social-list{
        display: flex;
        align-items: center;
        .cx--footer-social-item{
            margin-right: 10px;
            opacity: 0.5;
            transition: all 0.3s;
            img{
                max-width: 40px;
            }
            &:hover{
                opacity: 1;
            }
        }
    }
}